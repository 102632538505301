import React from "react";

const sizes = {
  s: "text-lg font-bold",
  md: "text-[26px] font-bold md:text-2xl sm:text-[22px]",
  xs: "text-base font-semibold",
};

const Heading = ({
  children,
  className = "",
  size = "md",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component
      className={`border-b-1 text-black-900_04 font-gotham pb-[16px] md:mt-[16px] w-full ${className} ${sizes[size]}`} style={{ borderColor: "#D5BF87" }}
      {...restProps}
    >
      <div className="border-b-1" style={{ borderColor: "#D5BF87" }}>
        {children}
      </div>
    </Component>
  );
};

export { Heading };
