import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getFlagCode = (language) => {
    switch (language) {
      case 'en':
        return 'US';
      case 'tr':
        return 'TR';
      default:
        return 'US';
    }
  };

  return (
    <div className="relative inline-block text-left sm:w-full sm:block">
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center w-full text-sm font-medium sm:p-[14px]"
        style={{color: "white", marginLeft: "10px"}}
      >
        <ReactCountryFlag
          countryCode={getFlagCode(i18n.language)}
          svg
          style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
        />
        {i18n.language === 'en' ? 'EN' : 'TR'}
      </button>

      {dropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white sm:w-full" style={{backgroundColor: "rgba(0,0,0,0.7)"}}>
          <div className="py-1">
            <button
              onClick={() => changeLanguage('en')}
              className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-700 sm:p-[14px] sm:text-center" style={{color: "white"}}
            >
              <ReactCountryFlag
                countryCode="US"
                svg
                style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
              />
              EN
            </button>
            <button
              onClick={() => changeLanguage('tr')}
              className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-700 sm:p-[14px] sm:text-center" style={{color: "white"}}
            >
              <ReactCountryFlag
                countryCode="TR"
                svg
                style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
              />
              TR
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
