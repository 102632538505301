import { useTranslation } from "react-i18next";
import { Text } from "../Text";

function Head({ name = "", title }) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`relative top-0 flex h-[851px] w-full max-w-[1920px] self-stretch bg-cover bg-no-repeat md:h-[500px] head ${name}`}
    >
      {/* header section */}
      <div className="flex w-full flex-col items-center justify-center gap-[242px] bg-gradient1  pt-[85px] md:gap-[181px] md:pb-[13px] md:pt-5 sm:gap-[121px] sm:pt-20">
        <div
          className={`container-xs px-[24px]  ${
            title === "Odalar"
              ? "flex flex-col justify-center items-center"
              : "sm:flex sm:flex-col sm:justify-center sm:items-center"
          }  md:p-5 md:px-5 sm:justify-center`}
        >
          {title == 1 ? (
            <Text
              size="xl"
              as="h1"
              className={`!font-alexbrush text-[100px] !text-lime-300 md:text-[65px] w-[500px] sm:w-full sm:text-center`}
            >
              {t("home")}
            </Text>
          ) : (
            <Text
              size="xl"
              as="h1"
              className={`!font-alexbrush text-[100px] !text-lime-300 md:text-[65px]sm:w-full sm:text-center`}
            >
              {t("rooms")}
            </Text>
          )}

          <a
            href="http://casa-alegre-alacati.hmshotel.net/"
            color="white_A700_04"
            size="md"
            shape="round"
            className="bg-[#fff] px-[32px] py-[16px] rounded-[8px] w-max font-gotham font-medium sm:px-5 mt-[60px]"
            style={{ color: "#D5BF87" }}
            target="_blank"
            rel="noreferrer"
          >
            {t("doReservation")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Head;
