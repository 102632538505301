import React from "react";

const sizes = {
  xs: "text-base font-normal",
  lg: "text-4xl font-normal md:text-[34px] sm:text-[32px]",
  s: "text-xl font-normal",
  "2xl": "text-9xl font-normal md:text-5xl",
  xl: "text-[98px] font-normal md:text-5xl",
  md: "text-3xl font-medium md:text-[28px] sm:text-[26px]",
};

const Text = ({
  children,
  className = "",
  as,
  size = "xs",
  color = "black",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`${
        color === "black" ? "text-black-900_04" : "text-white"
      } font-montserrat ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
