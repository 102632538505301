import React, { useState, useEffect, useContext } from "react";
import Title from "../Title";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Text } from "../Text";
import { Heading } from "../Heading";
import RoomProperty from "../RoomProperty";
import LazyImage from "../LazyImage";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";

export default function Rooms({ page = "home", rooms }) {
  const [selectedImgs, setSelectedImgs] = useState({});
  const [selectedImgIndex, setSelectedImgIndex] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRoomImages, setCurrentRoomImages] = useState([]);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const { t } = useTranslation();
  // Reset state when the page prop changes
  useEffect(() => {
    setSelectedImgs({});
    setSelectedImgIndex({});
    setIsModalOpen(false);
    setCurrentRoomImages([]);
    setCurrentModalImage("");
  }, [page]);

  const openModal = (images, selectedImage) => {
    setCurrentRoomImages(images);
    setCurrentModalImage(selectedImage);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = (roomIndex, imgIndex, src) => {
    setSelectedImgs((prev) => ({
      ...prev,
      [roomIndex]: src,
    }));
    setSelectedImgIndex((prev) => ({
      ...prev,
      [roomIndex]: imgIndex,
    }));
  };

  const handleModalImageClick = (src) => {
    setCurrentModalImage(src);
  };

  return (
    <div
      className="container-xs mt-[153px] flex flex-col gap-[70px] md:w-full md:gap-[52px] p-8 sm:gap-[35px]"
      id="rooms"
    >
      <Title position={"center"} title={"rooms"}>
        <img
          src="/images/title_logo.svg"
          width={120}
          height={120}
          alt="room"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[120px] w-[120px]"
        />
      </Title>
      <div className="flex flex-col gap-[188px] md:gap-[80px]">
        {rooms.map((item, roomIndex) => (
          <div
            key={roomIndex}
            className={`flex ${
              page === "home" ? "items-center justify-between" : "items-start"
            } gap-[24px] md:flex-col md:p-0`}
          >
            <div
              className={`flex w-[49%] justify-center md:w-[100%] ${
                roomIndex % 2 === 0 ? "-order-1" : "-order-2 md:-order-1"
              }`}
            >
              <div className="relative flex w-full flex-col items-center">
                <LazyImage
                  src={selectedImgs[roomIndex] || item.img_head_src}
                  alt="room thumbnail"
                  className="h-[424px] w-full rounded-[16px] md:h-auto object-cover cursor-pointer"
                  onClick={() =>
                    openModal(
                      item.img_list,
                      selectedImgs[roomIndex] || item.img_head_src
                    )
                  }
                />
                <div
                  className={`flex gap-5 ${
                    page === "home"
                      ? "mt-[-33px] w-[348px] justify-between"
                      : "mt-[24px] flex-wrap w-full"
                  }`}
                >
                  {item.img_list.map((d, imgIndex) => (
                    <LazyImage
                      key={imgIndex}
                      src={d.img_src}
                      alt={`gallery image ${imgIndex + 1}`}
                      className={`h-[66px] w-[100px] rounded-lg cursor-pointer ${
                        selectedImgIndex[roomIndex] === imgIndex
                          ? "border-4 border-[#D5BF87]"
                          : ""
                      }`}
                      onClick={() =>
                        handleClick(roomIndex, imgIndex, d.img_src)
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
            <div
              className="flex w-[43%] flex-col items-start md:w-full md:text-center"
              style={roomIndex % 2 === 0 ? { order: 2 } : { order: 1 }}
            >
              <Heading as="h1" className="">
                {t(item.name)}
              </Heading>
              <Text
                as="p"
                className="mt-[24px] w-full leading-[195%]  md:mt-[16px]"
              >
                {t(item.desc)}
              </Text>
              <div className="mt-[50px]  w-full  flex items-center justify-between gap-5 md:flex-wrap md:mt-[40px] md:flex-row md:justify-center md:gap-10">
                {item.bed2 && (
                  <RoomProperty
                    name={`2 ${t("person")}`}
                    img_src={"/images/r_bed.png"}
                  />
                )}
                {item.bed1 && (
                  <div className="flex flex-col items-center gap-[7px] md:w-[60px]">
                    <img
                      src="/images/r_bed.png"
                      width={50}
                      height={50}
                      alt="bed icon"
                      className="h-[50px] w-[50px] object-cover md:h-auto md:w-[100px]"
                    />
                    <Text as="p">2 {t("person")}</Text>
                  </div>
                )}

                {item.wc && (
                  <RoomProperty
                    name={"WC"}
                    img_src={"/images/r_washbasin.png"}
                  />
                )}

                {item.tv && (
                  <RoomProperty
                    name={"TV"}
                    img_src={"/images/r_television.png"}
                  />
                )}

                {item.wifi && (
                  <RoomProperty name={"Wifi"} img_src={"/images/r_wifi.png"} />
                )}

                {item.climate && (
                  <RoomProperty
                    name={t("climate")}
                    img_src={"/images/r_air.png"}
                  />
                )}

                {item.avlu && (
                  <RoomProperty
                    name={t("courtyard")}
                    img_src={"/images/r_double_door.png"}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        {page === "home" && (
          <div className="flex items-center w-full md:text-center">
            <div className="flex gap-6 sm:self-stretch sm:gap-[-30px]">
              <LazyImage
                src="/images/estrella/1.jpg"
                alt="95"
                className="h-[66px] w-[100px] rounded-lg object-cover"
              />
              <LazyImage
                src="/images/gabriella/1.jpg"
                alt="rectangle 46"
                className="h-[66px] w-[100px] rounded-lg object-cover"
              />
              <LazyImage
                src="/images/sofia/1.jpg"
                alt="96"
                className="h-[66px] w-[100px] rounded-lg object-cover sm:hidden"
              />
            </div>
            <div className="flex items-center gap-5 ml-10">
              <a className="flex" href={"/odalarimiz"}>
                <Text as="p" className="self-start !font-gotham !text-lime-300">
                  {t("seeAllRooms")}
                </Text>
                <DoubleRightOutlined
                  style={{ fontSize: "24px", color: "#D5BF87" }}
                />
              </a>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedImage={currentModalImage}
        images={currentRoomImages}
        onImageClick={handleModalImageClick}
      />
    </div>
  );
}
