import Navbar from "../../../components/Navbar";
import Rooms from "../../../components/Rooms";
import Head from "../../../components/Head";
import Aboutus from "../../../components/Aboutus";
import KokteylBar from "../../../components/KokteylBar";
import Guide from "../../../components/Guide";
import Footer from "../../../components/Footer";
import roomsHome from "../../../data/roomsHome";
import Gallery from "../../../components/Gallery";
import Reservation from "../../../components/Reservation";

export default function Homepage() {
  return (
    <div className="flex w-full flex-col items-center justify-center bg-gray-50_04 pb-[30px] md:pb-5">
      <Navbar page={"h"}/>
      <Head title={1} />

      <Aboutus />

      <Rooms page="home" rooms={roomsHome}/>

      <Gallery/>

      <KokteylBar />


      <Guide />

      <Reservation/>
      
      <Footer />
    </div>
  );
}
