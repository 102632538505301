import { Text } from "../Text";
import Title from "../Title";
import { useTranslation } from "react-i18next";

export default function KokteylBar() {
  const{t} = useTranslation();
  return (
    <div className="container-xs flex p-8 md:p-5 mt-[170px]" id="koktelyBar">
      <div className="flex w-full flex-col gap-[50px]">
        <Title
          position={"right"}
          title={"Alegre Cocktail & Drinks"}
          classW="w-[460px]"
        >
          <img
            src="images/title_logo.svg"
            width={120}
            height={120}
            alt="room image"
            className="absolute bottom-0 right-0 top-0 m-auto h-[120px] w-[120px]"
          />
        </Title>
        <div className="mt-[-200px] flex flex-wrap items-end justify-center gap-[70px] sm:mt-0">
          <img
            src="images/kokteyl_1.png"
            width={370}
            height={558}
            alt="coctailred 1"
            className="h-[558px] w-[370px] rounded-[16px] object-cover md:w-[40%] sm:hidden"
          />
          <img
            src="images/kokteyl_2.png"
            width={295}
            height={349}
            alt="martini glass a"
            className="h-[349px] w-[295px] rounded-[16px] object-cover md:w-[50%] sm:w-full mb-[-50px] sm:mb-[0px]"
          />
          <img
            src="images/kokteyl_3.png"
            width={437}
            height={389}
            alt="closeup shot cocktail bar"
            className="h-[389px] w-[437px] rounded-[16px] object-cover mb-[-30px]"
          />
        </div>
        <div className="flex w-[97%] items-start justify-between gap-5 md:w-full md:flex-col mt-[50px]">
          <Text as="p" className="w-[73%] leading-[239%] md:w-full">
            {t("kokteylDesc")}
          </Text>
          <a
            href="/menu"
            target="_blank"
            className="mt-[25px] gap-[11px] py-4 px-8 font-medium uppercase sm:px-5 "
            style={{ backgroundColor: "#D5BF87", color: "#fff"}}
          >
            {t("menu")}
          </a>
        </div>
      </div>
    </div>
  );
}
