import {
  CopyrightOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="container-xs mt-[180px] flex justify-center p-5">
      <div className="flex w-full flex-col items-center gap-[42px]">
        <div className="flex items-center justify-between gap-5 self-stretch md:flex-col">
          <div className="flex w-[43%] flex-col items-start md:w-full md:items-center">
            <img
              src="/images/footer_logo.svg"
              width={524}
              height={92}
              alt="company logo"
              className="h-[92px] w-full md:h-auto md:w-[50%]"
            />
            <Text
              size="s"
              as="p"
              className="mt-[68px] w-[75%] !font-gotham leading-6 md:w-full md:text-center"
            >
              <>{t("footerDesc")}</>
            </Text>
            <div className="mt-[52px]">
              <a
                className="flex gap-5 hover:pl-3 ease-in duration-200"
                target="_blank"
                href="tel:+905055442272"
              >
                <PhoneOutlined style={{ fontSize: "24px" }} />
                <Text size="s" as="p" className="self-start !font-gotham">
                  +90 505 544 22 72
                </Text>
              </a>
            </div>
            <div className="mt-[24px]">
              <a
                className="flex gap-5 hover:pl-3 ease-in duration-200"
                target="_blank"
                href="https://wa.me/5055442272"
              >
                <WhatsAppOutlined style={{ fontSize: "24px" }} />
                <Text size="s" as="p" className="self-start !font-gotham">
                  +90 505 544 22 72
                </Text>
              </a>
            </div>
            <div className="mt-[24px] flex gap-5">
              <MailOutlined style={{ fontSize: "24px" }} />
              <Text size="s" as="p" className="self-start !font-gotham">
                info@casaalegrealacati.com
              </Text>
            </div>
            <div className="mt-[24px] ">
              <a
                className="flex gap-5 hover:pl-3 ease-in duration-200"
                target="_blank"
                href="https://www.instagram.com/casaalegrealacati/"
              >
                <InstagramOutlined style={{ fontSize: "24px" }} />
                <Text size="s" as="p" className="self-start !font-gotham">
                  @casaalegrealacati
                </Text>
              </a>
            </div>
            {/* <div className="mt-[42px] h-px w-full self-stretch bg-lime-300" />
            <div className="mt-[41px] flex w-[32%] justify-between gap-5 md:w-full">
              <a href="/">
                <TwitterOutlined style={{ fontSize: "24px" }} />
              </a>
              <a href="/">
                <FacebookFilled style={{ fontSize: "24px" }} />
              </a>
              <a href="/">
                <LinkedinFilled style={{ fontSize: "24px" }} />
              </a>
            </div> */}
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3131.8081976450762!2d26.371557676481995!3d38.28393877186154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bb792670700ef7%3A0x3f93955fc94a6138!2sCasa%20Alegre%20Boutique%20Otel!5e0!3m2!1str!2str!4v1715036071187!5m2!1str!2str"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="md:w-full"
          ></iframe>
        </div>
        <div className="h-px w-full self-stretch bg-lime-300" />
        <div className="flex items-center gap-[9px] sm:flex-col">
          <CopyrightOutlined style={{ fontSize: "24px", color: "#858585" }} />
          <p className="!text-gray-600_04">
            Copyright 2024 by HSSDigital. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
