import React from "react";
import { Modal as AntdModal } from "antd";
import LazyImage from "../LazyImage";

const Modal = ({ isOpen, onClose, selectedImage, images, onImageClick }) => {
  return (
    <AntdModal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      className="custom-modal"
      centered
      width="80%"
    >
      <div className="flex flex-col items-center">
        <LazyImage
          src={selectedImage}
          alt="current selected"
          className="mb-4 max-h-[70vh]"
        />
        <div className="flex gap-4 mt-4 overflow-x-auto">
          {images.map((img, index) => (
            <LazyImage
              key={index}
              src={img.img_src}
              alt={`image ${index}`}
              className={`h-24 w-24 cursor-pointer ${
                selectedImage === img.img_src ? "border-4 border-[#D5BF87]" : ""
              }`}
              onClick={() => onImageClick(img.img_src)}
            />
          ))}
        </div>
      </div>
    </AntdModal>
  );
};


export default Modal;
