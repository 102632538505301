import React, { useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Text } from "../Text";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";

export default function Navbar({ page }) {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="absolute z-40 top-6 container-xs flex flex-col items-center p-5 pb-[30px]">
      <div className="relative flex w-full items-center justify-between gap-5 md:w-full mb-2">
        <a href="/">
          <img
            src="/images/logo.svg"
            width={317}
            height={56}
            alt="logo image"
            className="h-[56px] w-[317px] object-contain md:w-[200px]"
          />
        </a>
        <div className="flex align-center sm:hidden">
          <a href={`${page === "h" ? "#about" : "/"}`} className="mr-[16px]">
            <Text as="p" className="!font-poppins !text-white-A700">
              {t("about")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#rooms" : "/odalarimiz"}`}
            className="mr-[16px]"
          >
            <Text as="p" className="!font-poppins !text-white-A700">
              {t("rooms")}
            </Text>
          </a>
          <a href={`${page === "h" ? "#gallery" : "/"}`} className="mr-[16px]">
            <Text as="p" className="!font-poppins !text-white-A700">
              {t("art")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#kokteylBar" : "/"}`}
            className="mr-[16px]"
          >
            <Text as="p" className="!font-poppins !text-white-A700">
              {t("kokteylBar")}
            </Text>
          </a>
          <a href={`${page === "h" ? "#guide" : "/"}`}>
            <Text as="p" className="!font-poppins !text-white-A700">
              {t("guide")}
            </Text>
          </a>
          <LanguageSelector/>
        </div>
        <div
          className="border rounded w-[40px] h-[40px] flex items-center justify-center hover:bg-white hidden sm:flex mobile-nav"
          style={{ borderColor: "white" }}
          onClick={toggleMobileMenu}
        >
          <MenuOutlinedIcon
            className="hover:bg-red"
            style={{ color: "white" }}
          />
        </div>
      </div>
      <div className="h-px self-stretch bg-white-A700_42_04 " />
      {isMobileMenuOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#000] bg-opacity-80 z-500 flex flex-col items-center p-5 pt-28">
          <button
            className="absolute top-5 right-5 text-white"
            onClick={toggleMobileMenu}
            style={{ color: "white" }}
          >
            {t("close")}
          </button>
          <a
            href={`${page === "h" ? "#about" : "/"}`}
            className="mb-4 block text-center p-[14px] w-full"
          >
            <Text
              as="p"
              className="!font-poppins !text-white"
              style={{ color: "#fff" }}
            >
              {t("about")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#rooms" : "/odalarimiz"}`}
            className="mb-4 block text-center p-[14px] w-full"
          >
            <Text
              as="p"
              className="!font-poppins !text-white"
              style={{ color: "#fff" }}
            >
              {t("rooms")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#gallery" : "/"}`}
            className="mb-4 block text-center p-[14px] w-full"
          >
            <Text
              as="p"
              className="!font-poppins !text-white"
              style={{ color: "#fff" }}
            >
              {t("art")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#kokteylBar" : "/"}`}
            className="mb-4 block text-center p-[14px] w-full"
          >
            <Text
              as="p"
              className="!font-poppins !text-white"
              style={{ color: "#fff" }}
            >
              {t("kokteylBar")}
            </Text>
          </a>
          <a
            href={`${page === "h" ? "#guide" : "/"}`}
            className="block text-center p-[14px] w-full"
          >
            <Text
              as="p"
              className="!font-poppins !text-white"
              style={{ color: "#fff" }}
            >
              {t("guide")}
            </Text>
          </a>
          
          <LanguageSelector/>
        </div>
      )}
    </header>
  );
}
