import { Text } from "../Text";
import { useTranslation } from "react-i18next";

export default function Reservation() {
  const { t } = useTranslation();
  return (
    <div id="reservation" className="bg-[#D5BF87] w-full mt-[180px] h-[295px]">
      <div className="relative  container-xs flex items-center h-full p-5">
        <div className="flex flex-col gap-[24px] w-[500px] md:w-full">
          <Text
            className="text-[21px] leading-9 text-white"
            color="#fff"
            style={{ color: "#fff" }}
          >
            {t("reservationDesc")}
          </Text>
          <a
            href="http://casa-alegre-alacati.hmshotel.net/"
            color="white_A700_04"
            size="md"
            shape="round"
            className="relative z-[100] bg-[#fff] px-[32px] py-[16px] rounded-[8px] font-gotham font-medium sm:px-5 w-max"
            style={{ color: "#D5BF87" }}
            target="_blank"
            rel="noreferrer"
          >
            {t("doReservation")}
          </a>
          <img
            src="/images/rez.png"
            alt="reservation"
            className="absolute bottom-0 right-0 md:hidden"
            width={576}
            height={363}
          />
          <img
            src="/images/logo-white.png"
            alt="reservation"
            className="absolute top-50"
            width={200}
            height={200}
          />
        </div>
      </div>
    </div>
  );
}
