// LazyImage.js
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({ src, alt, className, onClick }) => (
  <LazyLoadImage
    src={src}
    alt={alt}
    className={className}
    onClick={onClick}
    effect="blur"
  />
);

export default LazyImage;
