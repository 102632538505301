import React from "react";
import { Text } from "../Text";

function RoomProperty({ name, img_src }) {
  return (
    <div className="flex flex-col items-center gap-1.5 md:w-[60px]">
      <img
        src={img_src}
        width={50}
        height={50}
        alt="wifi icon"
        className="h-[50px] w-full object-cover md:h-auto"
      />
      <Text as="p">{name}</Text>
    </div>
  );
}

export default RoomProperty;
