import { Text } from "../Text";
import { useTranslation } from "react-i18next";

function Title({
  position,
  title,
  children,
  color = "yellow",
  classW = "w-[220px]",
}) {
  const { t } = useTranslation();
  // const image = color === "yellow" ? "img_group_106.svg": "img_group_106_white_a700.svg";
  return position === "center" ? (
    <div className="flex w-full items-center gap-8 md:w-full">
      <div
        className="h-px flex-1"
        style={{
          backgroundColor: color === "yellow" ? "#D5BF87" : "#fff",
        }}
      />
      <div className={`relative h-[120px] w-[220px] ${classW}`}>
        {children}

        <Text
          size="md"
          as="p"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-max text-center !font-gotham uppercase !text-lime-300"
        >
          {t(`${title}`)}
        </Text>
      </div>
      <div
        className="h-px flex-1"
        style={{
          backgroundColor: color === "yellow" ? "#D5BF87" : "#fff",
        }}
      />
    </div>
  ) : position === "left" ? (
    <div className="flex w-full items-center gap-8 md:w-full">
      <div className={`relative h-[120px] w-[220px] ${classW}`}>
        {children}
        <Text
          size="md"
          as="p"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-max text-start !font-gotham uppercase !text-lime-300"
        >
          {t(`${title}`)}
        </Text>
      </div>
      <div
        className="h-px flex-1"
        style={{
          backgroundColor: color === "yellow" ? "#D5BF87" : "#fff",
        }}
      />
    </div>
  ) : position === "right" ? (
    <div className="flex w-full items-center gap-8 md:w-full">
      <div
        className="h-px flex-1"
        style={{
          backgroundColor: color === "yellow" ? "#D5BF87" : "#fff",
        }}
      />
      <div className={`relative h-[120px] w-[220px] ${classW}`}>
        {children}
        <Text
          size="md"
          as="p"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-max text-end !font-gotham uppercase !text-lime-300"
        >
          {t(`${title}`)}
        </Text>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Title;
