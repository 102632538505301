import React from "react";

const Menu = () => {

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="pdf-container w-full">
        <embed
          src="/menu.pdf"
          type="application/pdf"
          className="pdf-embed  w-full h-[100vh]"
        />
      </div>
    </div>
  );
};

export default Menu;
