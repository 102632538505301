const rooms = [
  {
    name: "roomTitle1",
    desc: "roomDesc1",
    img_head_src: "/images/isabella/1.jpg",
    img_list: [
      {
        img_src: "/images/isabella/1.jpg",
      },
      {
        img_src: "/images/isabella/2.jpg",
      },
      {
        img_src: "/images/isabella/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle2",
    desc: "roomDesc2",
    img_head_src: "/images/marisol/1.jpg",
    img_list: [
      {
        img_src: "/images/marisol/1.jpg",
      },
      {
        img_src: "/images/marisol/2.jpg",
      },
      {
        img_src: "/images/marisol/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: true,
  },
  {
    name: "roomTitle3",
    desc: "roomDesc3",
    img_head_src: "/images/catalina/1.jpg",
    img_list: [
      {
        img_src: "/images/catalina/1.jpg",
      },
      {
        img_src: "/images/catalina/2.jpg",
      },
      {
        img_src: "/images/catalina/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: true,
  },
  {
    name: "roomTitle4",
    desc: "roomDesc4",
    img_head_src: "/images/rosalia/1.jpg",
    img_list: [
      {
        img_src: "/images/rosalia/1.jpg",
      },
      {
        img_src: "/images/rosalia/2.jpg",
      },
      {
        img_src: "/images/rosalia/3.jpg",
      },
      {
        img_src: "/images/rosalia/4.jpg",
      },
      {
        img_src: "/images/rosalia/5.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle5",
    desc: "roomDesc5",
    img_head_src: "/images/valentina/1.jpg",
    img_list: [
      {
        img_src: "/images/valentina/1.jpg",
      },
      {
        img_src: "/images/valentina/2.jpg",
      },
      {
        img_src: "/images/valentina/3.jpg",
      },
      {
        img_src: "/images/valentina/4.jpg",
      },
      {
        img_src: "/images/valentina/5.jpg",
      },
      {
        img_src: "/images/valentina/6.jpg",
      },
      {
        img_src: "/images/valentina/7.jpg",
      },
      {
        img_src: "/images/valentina/8.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle6",
    desc: "roomDesc6",
    img_head_src: "/images/estrella/1.jpg",
    img_list: [
      {
        img_src: "/images/estrella/1.jpg",
      },
      {
        img_src: "/images/estrella/2.jpg",
      },
      {
        img_src: "/images/estrella/3.jpg",
      },
      {
        img_src: "/images/estrella/4.jpg",
      },
      {
        img_src: "/images/estrella/5.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle7",
    desc: "roomDesc7",
    img_head_src: "/images/sofia/1.jpg",
    img_list: [
      {
        img_src: "/images/sofia/1.jpg",
      },
      {
        img_src: "/images/sofia/2.jpg",
      },
      {
        img_src: "/images/sofia/3.jpg",
      },
      {
        img_src: "/images/sofia/4.jpg",
      },
      {
        img_src: "/images/sofia/5.jpg",
      },
      {
        img_src: "/images/sofia/6.jpg",
      },
      {
        img_src: "/images/sofia/7.jpg",
      },
      {
        img_src: "/images/sofia/8.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle8",
    desc: "roomDesc8",
    img_head_src: "/images/gabriella/1.jpg",
    img_list: [
      {
        img_src: "/images/gabriella/1.jpg",
      },
      {
        img_src: "/images/gabriella/2.jpg",
      },
      {
        img_src: "/images/gabriella/3.jpg",
      },
      {
        img_src: "/images/gabriella/4.jpg",
      },
      {
        img_src: "/images/gabriella/5.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle9",
    desc: "roomDesc9",
    img_head_src: "/images/camila/1.jpg",
    img_list: [
      {
        img_src: "/images/camila/1.jpg",
      },
      {
        img_src: "/images/camila/2.jpg",
      },
      {
        img_src: "/images/camila/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle10",
    desc: "roomDesc10",
    img_head_src: "/images/laura/1.jpg",
    img_list: [
      {
        img_src: "/images/laura/1.jpg",
      },
      {
        img_src: "/images/laura/2.jpg",
      },
      {
        img_src: "/images/laura/3.jpg",
      },
      {
        img_src: "/images/laura/4.jpg",
      },
      {
        img_src: "/images/laura/5.jpg",
      },
      {
        img_src: "/images/laura/6.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
];

export default rooms;
