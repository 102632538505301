import Footer from "../../../components/Footer";
import Head from "../../../components/Head";
import Navbar from "../../../components/Navbar";
import Rooms from "../../../components/Rooms";
import rooms from "../../../data/rooms";

export default function Odalarimiz() {
  return (
    <div className="flex w-full flex-col items-center justify-center bg-gray-50_04 pb-[30px] md:pb-5">
      <Navbar />
      <Head title={"Odalar"} name="room"/>

      <Rooms page="odalarimiz" rooms={rooms}/>

      <Footer />
    </div>
  );
}
