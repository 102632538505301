import { Text } from "../Text";
import Title from "../Title";
import { useTranslation } from "react-i18next";

function Aboutus() {
  const { t } = useTranslation();

  return (
    <div
      className="container-xs mt-16 flex items-center justify-between gap-5 md:flex-col p-5"
      id="about"
    >
      <div className="flex w-[69%] flex-col gap-[50px] md:w-full">
        <Title position="left" title="about" color="yellow">
          <img
            src="/images/title_logo.svg"
            width={120}
            height={120}
            alt="logo image"
            className="absolute bottom-0 left-0  top-0 m-auto h-[120px] w-[120px]"
          />
        </Title>
        <div className="flex flex-col gap-[60px] sm:gap-[30px]">
          <Text
            size="lg"
            as="p"
            className="w-[87%] !font-gotham leading-[43px] md:w-full"
          >
            {t("aboutTitle")}
          </Text>
          <Text as="p" className="leading-[239%]">
            {t("aboutDesc")}
          </Text>
        </div>
      </div>
      <div className="w-[26%] rounded-bl-[200px] rounded-br-[150px] rounded-tl-md rounded-tr-md  md:w-full">
        <img
          src="/images/about_8.jpg"
          width={336}
          height={671}
          alt="about"
          className="h-[671px] w-full rounded-tl-[200px] rounded-tr-[200px] object-cover md:hidden md:h-auto"
        />
      </div>
    </div>
  );
}

export default Aboutus;
