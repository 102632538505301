// src/data/roomsHome.js
const roomsHome = [
  {
    name: "roomTitle1",
    desc: "roomDesc1",
    img_head_src: "/images/isabella/1.jpg",
    img_list: [
      {
        img_src: "/images/isabella/1.jpg",
      },
      {
        img_src: "/images/isabella/2.jpg",
      },
      {
        img_src: "/images/isabella/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: false,
  },
  {
    name: "roomTitle2",
    desc: "roomDesc2",
    img_head_src: "/images/marisol/1.jpg",
    img_list: [
      {
        img_src: "/images/marisol/1.jpg",
      },
      {
        img_src: "/images/marisol/2.jpg",
      },
      {
        img_src: "/images/marisol/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: true,
  },
  {
    name: "roomTitle3",
    desc: "roomDesc3",
    img_head_src: "/images/catalina/1.jpg",
    img_list: [
      {
        img_src: "/images/catalina/1.jpg",
      },
      {
        img_src: "/images/catalina/2.jpg",
      },
      {
        img_src: "/images/catalina/3.jpg",
      },
    ],
    bed2: true,
    bed2_count: 1,
    bed1: false,
    bed1_count: null,
    wc: true,
    tv: true,
    wifi: true,
    climate: true,
    avlu: true,
  },
];

export default roomsHome;
